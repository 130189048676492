import React from "react"

export default function QaItem({ title, content, index, setOpens, opens, faqClick }) {
  // const [open, setOpen] = useState(false)

  const handleClick = () => {
    // setOpen(!open)
    let faqTitle;
    if (opens[index]) {
      setOpens([false, false, false, false])
    } else {
      let all = [false, false, false, false]
      all[index] = true
      setOpens(all)
    }
    if(faqClick) {
      if (index === 0) {
        faqTitle = "what is e-invoicing"
      } else if (index === 1) {
        faqTitle = "when and why do i need to start planning for e-invoicing"
      } else if (index === 2) {
        faqTitle = "who is LUCA Plus"
      } else if (index === 3) {
        faqTitle = "How does LUCA Plus and MYOB work together"
      } else if (index === 4) {
        faqTitle = "What data dos LUCA Plus see"
      }
      faqClick(faqTitle);
    }
  }

  const toggleCls = opens[index] ? "item-close" : "item-open"
  const cotentCls = opens[index] ? "block" : "hidden"
  return (
    <li className={`item ${toggleCls}`} onClick={() => handleClick(title)}>
      <p className="faq_question pr-2" dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={`${cotentCls} faq_answer`}
      >{content}</p>
    </li>
  )
}
