import React, {useState} from "react"
import QaItem from "../../components/new-landing/qa-item"
import Top from "../../components/luca-pay/top";
import classNames from "classnames";
import Switch from "rc-switch";
import SEO from "../../components/seo";
import seoImage from "../../images/luca-pay/bg1.png";

export default function Faq() {
    const [opens, setOpens] = useState([false, false, false, false])
    const [purchaser, setPurchaser] = useState(false);
    const onChange = () => {
        setPurchaser(!purchaser)
    }
    const faqs = purchaser ? purchaserFAQ : supplierFAQ
    return (
        <div className="lucapay h-full">
            <SEO title="LUCA Pay FAQs | LUCA+ Australia" description=""
                 keywords="LUCA Pay FAQs" image={seoImage}/>
            <Top/>
            <div className="faq">
                <div className="faq__top">
                    <div className="overlay"/>
                    <div className="container z-20">
                        <div className="title">
                            LUCA Pay FAQ
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label className={classNames({"active": !purchaser})}>Supplier</label>
                <Switch
                    className="mx-3"
                    onChange={onChange}
                    checked={purchaser}
                />
                <label className={classNames({"active": purchaser})}>Purchaser</label>
            </div>
            <div className="container flex flex-col  sm:flex-row justify-center items-end">
                <div className="text-left w-full sm:w-auto">
                    <ul className="faq__list leading-5 text-neutral-3 font-medium">
                        {faqs.map(({title, text}, index) => (
                            <QaItem
                                title={title}
                                content={text}
                                key={`item-${index}`}
                                index={index}
                                setOpens={setOpens}
                                opens={opens}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const purchaserFAQ = [
    {
        title: "1. How does LUCA Pay work?",
        text: ""
    }, {
        title: "2. What type of ID check and documentation is required to apply for LUCA Pay?",
        text: <div>
            <div className="mb-1">We use a third party ‘Green ID’ for the ID check.</div>
            A copy of your Australian Driver Licence and passport is required when applying for LUCA Pay.
        </div>
    },
    {
        title: "3. How are my personal details and documentation stored?",
        text: ""
    }, {
        title: "4. Once my application is approved, what happens next?",
        text: "Your nominated account will be direct debited in 4 equal instalment over 6 weeks"

    }, {
        title: "5. If my application is not successful what happens next?",
        text: "We will contact you for your individual assessment."
    }, {
        title: "6. If my application is not successful does this affect my credit rating?",
        text: "Not at all"

    }, {
        title: "7. If my first application is not successful, can I re-apply?",
        text: "Sure, once we have more information about your business."
    }, {
        title: "8. What security checks do you perform to ensure I am a business?",
        text: "Valid ABN register for GST plus positive cashflow that make sure enough balance for direct debit."

    }, {

        title: "9. Do you connect my bank account with LUCA Pay to ensure I have funds to pay back LUCA Pay?",
        text: "Yes, we will use third party bank feed."
    }, {
        title: "10. What is the approval process and how long does it take?",
        text: "Our approval process is automatically run by our algorithm which only takes one minute."

    }, {

        title: "11. How does LUCA Plus make the decision if I am approved or rejected to use LUCA Pay?",
        text: "We have a machine learning algorithm to access your ability to reply based on the information that we have."
    }, {
        title: "12. Do I need to be an existing LUCA Plus user to use LUCA Pay?",
        text: "Yes, as payment method will be on the e-invoicing."

    }, {
        title: "13. Do I automatically qualify for LUCA Pay if I am a LUCA Plus user or do I still need to apply?",
        text: "You still need to apply."
    }, {
        title: "14. Is there a cost to use LUCA Pay?",
        text: "Free, as long as enough balance for the direct debit, otherwise there will be a late fee."

    }, {
        title: "15. What is the payout limit per invoice using LUCA Pay?",
        text: "Up to Aud $10,000. For existing LUCA Pay users with a good credit history could apply up to $30,000."
    }, {
        title: "16. Are there any late payment charges on failed debit attempts?",
        text: "Yes, there will be $10 late fee for missing payments."

    }, {
        title: "17. How many times does LUCA Pay try to debit before late payment charges are applied?",
        text: "Twice, on the due date and two days after the due date"
    }, {
        title: "18. What are the fees incurred when paying out an invoice received via LUCA Pay?",
        text: "No Fee."

    }, {
        title: "19. Can I make early payments or pay my Payright plan out early?",
        text: "Yes, you can"
    }, {
        title: "20. When using LUCA Pay to pay out an invoice I received, what does my supplier receive to confirm payment has been made?",
        text: "Your Supplier will receive the full amount of the invoice on the due date, and we charge a 5% fee."

    }, {

        title: "21. What if I want to return my purchase?",
        text: "In the first instance, please contact the merchant directly regarding any concerns with the product or service. All requests for returns are subject to the Merchant’s return policy. If the request has been authorised, the Merchant will advise LUCA Pay regarding any cancellation or refund that is applicable to your payment plan."
    }, {
        title: "22. How do refunds work?",
        text: "Any amounts paid by you will be refunded back onto your nominated card and any future instalments will be cancelled."
    }, {
        title: "23. Can I make multiple purchases with Payright?",
        text: "Yes, but the amalgamated amount needs to be less than $10,000 with multiple suppliers, per supplier need to be less than $5,000."
    }, {
        title: "24. How can I find out the payout figure?",
        text: "You can check your payout figure anytime by logging in to lucapay.com."
    }]

export const supplierFAQ = [
    {
        title: "1. Is Payright safe and secure?",
        text: ""
    }, {
        title: "2. What type of ID check and documentation is required to apply for LUCA Pay?",
        text: "We use third party ‘Green ID’ for the ID check, which they require Australian Driver Licence or  passport",

    }, {

        title: "3. How are my personal details and documentation stored?",
        text: ""
    }, {
        title: "4. Once my application is approved, what happens next?",
        text: "Direct debit will happen to your nominated account with the full invoice amount on the due date, and at the same time LUCA Pay will charge 5% of the full invoice as service fee."

    }, {

        title: "5. If my application is not successful what happens next?",
        text: "As for the supplier you will be eligible to accept LUCA Pay payment, but whether or not your purchaser is eligible to pay by instalments depends on the purchaser’s credit history."
    }, {
        title: "6. What security checks do you perform to ensure I am a business?",
        text: "Valid ABN register for GST"

    }, {

        title: "7. What is the approval process and how long does it take?",
        text: "Our approval process is automatically run by our algorithm which only takes one minute"
    }, {
        title: "8. Do I need to be an existing LUCA Plus user to use LUCA Pay?",
        text: "Yes, as payment method will be on the e-invoicing"

    }, {

        title: "9. Do I automatically qualify for LUCA Pay if I am a LUCA Plus user or do I still need to apply?",
        text: "You still need to apply"
    }, {
        title: "10. Is there a cost to use LUCA Pay?",
        text: "LUCA Pay will charge 5% of the full invoice amount including GST"

    }, {

        title: "11. What is the payout limit per invoice using LUCA Pay?",
        text: "Up to Aud $10,000. For existing LUCA Pay users with a good credit history could apply up to $30,000"
    }, {
        title: "12. If my Purchaser is unable to pay my invoice, can I ask them to pay via LUCA Pay?",
        text: "Yes, you should."

    }, {

        title: "13. What reference will appear on my bank statement?",
        text: "It will be the invoice number on the supplier’s invoice"
    }]